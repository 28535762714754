import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom"; 
import { AuthContext } from "../../../../context/AuthContext";
import { getCourseDetails, checkEnrollmentStatus, enrollInCourse } from "../../../../api"; 
import { Chapter, Cloud, Play, Teacher, Timer2, Users, Video, Import, Key, Mobile, Video2, Chart } from "../../../imagepath";
import { toast } from "react-toastify";

const DetailsContent = () => {
    const { courseId } = useParams(); 
    const { isAuthenticated, userRole } = useContext(AuthContext); 
    const [course, setCourse] = useState(null);
    const [enrollmentStatus, setEnrollmentStatus] = useState(null); 
    const navigate = useNavigate();
 
    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const courseResponse = await getCourseDetails(courseId);
                setCourse(courseResponse);
                if (isAuthenticated) {
                    const statusResponse = await checkEnrollmentStatus(courseId);
                    setEnrollmentStatus(statusResponse.data.is_enrolled); 
                }
            } catch (error) {
                console.error("Error fetching course details or enrollment status:", error);
            }
        };

        fetchCourseDetails();
    }, [courseId, isAuthenticated]);

    const handleEnrollClick = async () => {
        if (isAuthenticated) {
            if (enrollmentStatus) {
                  const enrolledResponse = await checkEnrollmentStatus(courseId);
                  const enrollmentId = enrolledResponse.data.enrollment_id;
                 navigate(`/classroom/course/${courseId}/enrollments/${enrollmentId}`);
            } else {
                try {
                    const enrollInCourseResponse = await enrollInCourse(courseId);
                    const enrollmentId = enrollInCourseResponse.data.enrollment_id;

                    toast.success("Enrolled successfully!");

                    // Set timeout before navigating
                    setTimeout(() => {
                        navigate(`/classroom/course/${courseId}/enrollments/${enrollmentId}`);
                    }, 2000); // 2 seconds delay

                    setEnrollmentStatus(true); 
                } catch (error) {
                    console.error("Error enrolling in course:", error);
                    toast.error("Enrollment failed. Please try again.");
                }
            }
        } else {
            navigate("/login");
        }
    };

    if (!course) {
        return <div>Loading...</div>; 
    }

    return (
        <section className="page-content course-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        {/* Overview */}
                        <div className="card overview-sec">
                            <div className="card-body">
                                <h5 className="subs-title">Overview</h5>
                                <h6>Course Description</h6>
                                <p>{course.description}</p>
                                <h6>What you&apos;ll learn</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul>
                                            {course.objectives.split("\r\n").map((objective, index) => (
                                                <li key={index}>{objective}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Overview */}
                        {/* Course Content */}
                        <div className="card content-sec">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h5 className="subs-title">Course Content</h5>
                                    </div>
                                </div>
                                {course.sections.map((section) => (
                                    <div key={section.id} className="course-card">
                                        <h6 className="cou-title">
                                            <Link
                                                className="collapsed"
                                                data-bs-toggle="collapse"
                                                to={`#section${section.id}`}
                                                aria-expanded={false}
                                                onClick={(e) => {
                                                    const target = e.currentTarget.getAttribute("aria-expanded") === "false";
                                                    e.currentTarget.setAttribute("aria-expanded", target);
                                                }}
                                            >
                                                {section.title}
                                            </Link>
                                        </h6>
                                        <div id={`section${section.id}`} className="card-collapse collapse">
                                            <ul>
                                                {section.lessons.map((lesson) => (
                                                    <li key={lesson.id}>
                                                        <p>
                                                            {/* <img src={Play} alt="" className="me-2" /> */}
                                                            <i className="fa fa-play course-icon" aria-hidden="true"></i>
                                                            {lesson.title}
                                                        </p>
                                                        <div>
                                                            {/* <Link to="#">Preview</Link>
                                                            <span>02:53</span> */}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* /Course Content */}
                    </div>
                    <div className="col-lg-4">
                        <div className="sidebar-sec">
                            {/* Video */}
                            <div className="video-sec vid-bg">
                                <div className="card">
                                    <div className="card-body">
                                        <Link
                                            to="https://www.youtube.com/embed/1trvO6dqQUI"
                                            className="video-thumbnail"
                                            data-fancybox=""
                                        >
                                            <div className="play-icon">
                                                <i className="fa-solid fa-play" />
                                            </div>
                                            <img className="" src={Video} alt="" />
                                        </Link>
                                        <div className="video-details">
                                            <button
                                                className="btn btn-enroll w-100"
                                                onClick={handleEnrollClick}
                                            >
                                                {isAuthenticated ? (
                                                    enrollmentStatus ? "Go To Classroom" : "Enroll Now"
                                                ) : "Enroll Now"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Video */}
                            {/* Include */}
                            <div className="card include-sec">
                                <div className="card-body">
                                    <div className="cat-title">
                                        <h4>Includes</h4>
                                    </div>
                                    <ul>
                                        <li><i className="fa fa-cloud-download course-icon" aria-hidden="true"></i>
                                            Assignments
                                        </li>
                                        <li>
                                        <i className="fa fa-graduation-cap course-icon" aria-hidden="true"></i>
                                            Certificate of Completion
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* /Include */}
                            {/* Features */}
                            <div className="card feature-sec">
                                <div className="card-body">
                                    <div className="cat-title">
                                        <h4>Features</h4>
                                    </div>
                                    <ul>
                                        <li>
                                        <i className="fa fa-users course-icon" aria-hidden="true" ></i>
                                            Enrolled: <span>10+ students</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock course-icon" aria-hidden="true"></i>
                                            Duration: <span>{course.duration} hours</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-book course-icon" aria-hidden="true"></i>
                                            Sections: <span>{course.sections.length}</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-line-chart course-icon" aria-hidden="true"></i>
                                            Level: <span>Beginner</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* /Features */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DetailsContent;
