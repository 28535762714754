import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import Footer from "../../../footer";
import { Lock } from "../../../imagepath";
import PageHeader from "../../header";
import CourseHeader from "../header";
import './index.css';
import { getCourseDetails, getCourseProgress } from '../../../../api';

const CourseLesson = () => {
    const { courseId } = useParams();
    const [courseDetails, setCourseDetails] = useState(null);
    const [error, setError] = useState(null);
    const [dropdowns, setDropdowns] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    const [courseProgress, setCourseProgress] = useState(null);

    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const courseResponse = await getCourseDetails(courseId);
                setCourseDetails(courseResponse);

                const progressResponse = await getCourseProgress(courseId);
        setCourseProgress(progressResponse);

                if (courseResponse.sections.length > 0 && courseResponse.sections[0].lessons.length > 0) {
                    setSelectedLesson(courseResponse.sections[0].lessons[0]);
                }

                setDropdowns(new Array(courseResponse.sections.length).fill(false));
            } catch (error) {
                setError(error);
                console.error("Error fetching course details:", error);
            }
        };

        fetchCourseDetails();
    }, [courseId]);

    const toggleDropdown = (index) => {
        setDropdowns(prev => {
            const newDropdowns = [...prev];
            newDropdowns[index] = !newDropdowns[index];
            return newDropdowns;
        });
    };

    const handleLessonClick = (lesson) => {
        setSelectedLesson(lesson);
        setCurrentSlide(0); // Reset to the first slide when a new lesson is selected
    };

    const getLessonContentUrls = (lesson) => {
        if (lesson.content_type === "document") {
            return JSON.parse(lesson.content_file).map(file => `${API_URL}/${file}`);
        } else if (lesson.content_type === "video") {
            return [`${API_URL}/${lesson.content_file}`];
        } else if (lesson.content_type === "rich-text") {
            return [lesson.content];
        }
        return [];
    };

    const openSectionForLesson = (sectionIndex) => {
        setDropdowns(prev => {
            const newDropdowns = [...prev];
            newDropdowns[sectionIndex] = true;
            return newDropdowns;
        });
    };

    const navigateLesson = (direction) => {
        const currentSectionIndex = courseDetails.sections.findIndex(section =>
            section.lessons.some(lesson => lesson.id === selectedLesson.id)
        );
        const currentSection = courseDetails.sections[currentSectionIndex];
        const currentLessonIndex = currentSection.lessons.findIndex(lesson => lesson.id === selectedLesson.id);

        let newLesson = null;

        if (direction === 'next') {
            if (currentSlide < getLessonContentUrls(selectedLesson).length - 1) {
                // If there are more slides in the current lesson, move to the next slide
                setCurrentSlide(prevSlide => prevSlide + 1);
            } else if (currentLessonIndex < currentSection.lessons.length - 1) {
                // If on the last slide, move to the next lesson
                newLesson = currentSection.lessons[currentLessonIndex + 1];
                setSelectedLesson(newLesson);
                setCurrentSlide(0); // Reset slide index for new lesson
            } else {
                // Move to the next section's first lesson
                const nextSectionIndex = currentSectionIndex + 1;
                if (nextSectionIndex < courseDetails.sections.length) {
                    newLesson = courseDetails.sections[nextSectionIndex].lessons[0];
                    openSectionForLesson(nextSectionIndex);
                    setSelectedLesson(newLesson);
                    setCurrentSlide(0); // Reset slide index for new lesson
                }
            }
        } else if (direction === 'prev') {
            if (currentSlide > 0) {
                // Move to the previous slide
                setCurrentSlide(prevSlide => prevSlide - 1);
            } else if (currentLessonIndex > 0) {
                // If on the first slide, move to the previous lesson
                newLesson = currentSection.lessons[currentLessonIndex - 1];
                setSelectedLesson(newLesson);
                setCurrentSlide(0); // Reset slide index for new lesson
            } else {
                // Move to the previous section's last lesson
                const prevSectionIndex = currentSectionIndex - 1;
                if (prevSectionIndex >= 0) {
                    const prevSection = courseDetails.sections[prevSectionIndex];
                    newLesson = prevSection.lessons[prevSection.lessons.length - 1];
                    openSectionForLesson(prevSectionIndex);
                    setSelectedLesson(newLesson);
                    setCurrentSlide(0); // Reset slide index for new lesson
                }
            }
        }
    };

    const renderCarousel = (contentUrls) => {
        if (contentUrls.length > 0) {
            return (
                <Carousel
                    data-bs-theme="dark"
                    interval={null}
                    activeIndex={currentSlide}
                    onSelect={(selectedIndex) => setCurrentSlide(selectedIndex)}
                    controls={true}
                    indicators={false}
                    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" onClick={() => navigateLesson('next')} />}
                    prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" onClick={() => navigateLesson('prev')} />}
                >
                    {contentUrls.map((content, index) => (
                        <Carousel.Item key={index}>
                            {selectedLesson.content_type === 'video' ? (
                                <div className="video-container">
                                    <video controls>
                                        <source src={content} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ) : selectedLesson.content_type === 'rich-text' ? (
                                <div className="rich-text-content">
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            ) : (
                                <div>
                                    <img src={content} alt={`Slide ${index}`} />
                                </div>
                            )}
                        </Carousel.Item>
                    ))}
                </Carousel>
            );
        }

        return <div>No content available for this lesson.</div>;
    };

    if (error) {
        return <div>Error fetching course details: {error.message}</div>;
    }

    if (!courseDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="main-wrapper">
            <CourseHeader />
            <div className="row lesson-row">
                <div className="col-lg-4 col-md-6 lesson-content-card">
                    <div className="lesson-group curriculum-course-title">
                        <h5 className="course-title curriculum-content-header">{courseDetails.title}</h5>
                    </div>
                    {/* Progress Bar */}
                    <div className="progress-stip">
                    <div className="progress-bar bg-success progress-bar-striped active-stip" 
         role="progressbar" 
         style={{  width: `${courseProgress?.progress_percentage || 0}%` }} 
         aria-valuenow={courseProgress?.progress_percentage} 
         aria-valuemin={0} 
         aria-valuemax={100}>
        <span className="percentage-label">{courseProgress?.progress_percentage}%</span>
    </div>
                      </div>
                    <div className="lesson-group">
                        {courseDetails.sections.map((section, index) => (
                            <div className="course-card" key={section.id}>
                                <h6 className="cou-title">
                                    <Link
                                        className="collapsed"
                                        data-bs-toggle="collapse"
                                        to={`#section${index}`}
                                        onClick={() => toggleDropdown(index)}
                                        aria-controls={`section${index}`}
                                        aria-expanded={dropdowns[index]}
                                    >
                                        {section.title} <span>{section.lessons.length} Lessons</span>
                                    </Link>
                                </h6>
                                <Collapse in={dropdowns[index]}>
                                    <div id={`section${index}`} className="card-collapse collapse">
                                        <ul>
                                            {section.lessons.map(lesson => (
                                                <li key={lesson.id} onClick={() => handleLessonClick(lesson)}>
                                                    <p>{lesson.title}</p>
                                                    <div>
                                                        <img src={Lock} alt="" />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-lg-8 col-md-6">
                    <div className="student-widget lesson-introduction">
                        <div className="lesson-widget-group">
                            <h4 className="tittle">{selectedLesson?.title}</h4>
                            {selectedLesson ? (
                                renderCarousel(getLessonContentUrls(selectedLesson))
                            ) : (
                                <div>Select a lesson to view its content.</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseLesson;
