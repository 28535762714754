import React, { useEffect, useState } from "react";
import Footer from "../../footer";
import { InstructorHeader } from "../../instructor/header";
import { Icon1, Icon2, User1, User2, User3, User4, User5, User6, course02, course03, course04, course05, course07, course08, instructortabel01, instructortabel02, instructortabel03, instructortabel04, instructortabel05 } from "../../imagepath";
import AdminSidebar from "../sidebar";
import { getCourses } from "../../../api";
import { Link } from "react-router-dom";

export const AdminDashboard = () => {
  const [recentCourses, setRecentCourses] = useState([]);
  const API_URL = process.env.REACT_APP_MEDIA_URL

  useEffect(() => {
      const fetchCourses = async () => {
          try {
              const response = await getCourses();
              const courses = response.data.results // Get the courses from the response
              // Sort courses by created_at date and get the last 5
              
              const sortedCourses = courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              setRecentCourses(sortedCourses.slice(0, 5));
          } catch (error) {
              console.error("Error fetching courses:", error);
          }
      };

      fetchCourses();
  }, []);

  return (
      <div className="main-wrapper">
          <InstructorHeader activeMenu={"Dashboard"} />
          <div className="page-content">
              <div className="container">
                  <div className="row admin-row">
                      {/* Sidebar */}
                      <AdminSidebar />
                      <div className="col-xl-9 col-lg-9">
                          {/* Dashboard Grid */}
                          <div className="row justify-content-center">
                              {/* <div className="col-lg-4 col-md-6 d-flex">
                                  <div className="card dash-info flex-fill">
                                      <div className="card-body">
                                          <h5>Enrolled Courses</h5>
                                          <h2>13</h2>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 d-flex">
                                  <div className="card dash-info flex-fill">
                                      <div className="card-body">
                                          <h5>Total Students</h5>
                                          <h2>5</h2>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 d-flex">
                                  <div className="card dash-info flex-fill">
                                      <div className="card-body">
                                          <h5>Total Courses</h5>
                                          <h2>11</h2>
                                      </div>
                                  </div>
                              </div> */}
                          </div>
                          {/* /Dashboard Grid */}
                          <div className="instructor-course-table">
                              <div className="dashboard-title">
                                  <h4>Recently Created Courses</h4>
                              </div>
                              <div className="table-responsive custom-table">
                                  {/* Referred Users */}
                                  <table className="table table-nowrap mb-0">
                                      <thead>
                                          <tr>
                                              <th>Courses</th>
                                              <th>Enrolled</th>
                                              <th>Status</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {recentCourses.map((course, index) => (
                                              <tr key={course.id}>
                                                  <td>
                                                      <div className="table-course-detail">
                                                          <Link to={`/course-details/${course.id}`} className="course-table-img">
                                                              <span>
                                                                  <img
                                                                      src={`${API_URL}/${course.icon}`} // Assuming you want to use the course icon
                                                                      alt="Img"
                                                                  />
                                                              </span>
                                                              {course.title}
                                                          </Link>
                                                      </div>
                                                  </td>
                                                  <td>0</td>
                                                  <td>Published</td>
                                              </tr>
                                          ))}
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                      {/* Student Dashboard */}
                  </div>
              </div>
          </div>
          {/* /Page Content */}
          <Footer />
      </div>
  );
};
