import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer3 } from "../footer3";
import { Header3 } from "../header3";
import Companies from "../home/slider/companies";
import { useSelector } from "react-redux";
import {
  Career,
  Career01,
  Career02,
  Career03,
  Career04,
  Course01Icon,
  Course02Icon,
  Course03Icon,
  Course04Icon,
  Course26,
  HomeMain,
  HomeSlider,
  Pattern01,
  User1,
} from "../imagepath";
import { TopCategories2 } from "./slider/topCategories";
import { Trending3 } from "./slider/trendingCourse";
import { getCourses } from "../../api";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";

const options = [
  { label: "Select category", value: "category" },
  { label: "Angular", value: "Angular" },
  { label: "Node Js", value: "Node Js" },
  { label: "React", value: "React" },
  { label: "Python", value: "Python" },
];

export const Home3 = () => {
  const mobileSidebar = useSelector((state) => state.sidebarSlice.expandMenu);
  const [setValue] = useState(null);
  useEffect(() => {
    document.body.className = "home-three";

    return () => {
      document.body.className = "";
    };
  }, []);
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: mobileSidebar === "disabled" ? "#f7f7f7f7" : "#2b2838",
      width: "100%",
      height: "56px",
      minHeight: "56px",
      border: state.isFocused ? 0 : 0,
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      // borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
        color: "black",
      },
      outline: "none",
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      borderRadius: "0",
      hyphens: "auto",
      width: "max-content",
      minWidth: "100%",
    }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided) => ({
      ...provided,
      backgroundColor: mobileSidebar === "disabled" ? "#fff" : "#000",
      color: mobileSidebar === "disabled" ? "#000" : "#fff",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: mobileSidebar === "disabled" ? "#FFDEDA" : "#2b2838",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 6px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };

  const [toggle, setToggle] = useState(1);

  const updateToggle = (id) => {
    setToggle(id);
  };
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        setCourses(response.data.results);
      } catch (err) {
        setError("Failed to fetch courses");
      }
    };

    fetchCourses();
  }, []);
  return (
    <>
      <Header3 />
      {/* Home Banner */}
      <section
        className="home-three-slide d-flex align-items-center"
        style={{
          backgroundImage:
            mobileSidebar === "disabled" && "url(" + HomeMain + ")",
        }}
      >
        <div className="container">
          <div className="row ">
            <div
              className="col-xl-6 col-lg-8 col-md-12 col-12"
              data-aos="fade-down"
            >
              <div className="home-three-slide-face">
                <div className="home-three-slide-text">
                  <h5>The Leader in Online Learning</h5>
                  <h1>
                    Engaging <span>&</span> Accessible Online Courses For All
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Eget aenean accumsan bibendum gravida maecenas augue
                    elementum et
                  </p>
                </div>
                <div className="banner-three-content"></div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-4 col-md-6 col-12"
              data-aos="fade-up"
            >
              <div className="girl-slide-img aos">
                <img className="img-fluid" src={HomeSlider} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Banner */}
      {/* Online Courses */}
      <section className="section student-course home-three-course">
        <div className="container">
          <div className="course-widget-three">
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three">
                  <div className="align-items-center">
                    <div className="course-count-three course-count ms-0">
                      <div className="course-img">
                        <img className="img-fluid" src={Course01Icon} alt="" />
                      </div>
                      <div className="course-content-three">
                        <h4 className="text-blue">
                          <span className="d-flex">
                            <CountUp
                              start={0}
                              end={10}
                              delay={1}
                              duration={4}
                            />
                            K
                          </span>
                        </h4>
                        <p>Online Courses</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three">
                  <div className="align-items-center">
                    <div className="course-count-three course-count ms-0">
                      <div className="course-img">
                        <img className="img-fluid" src={Course02Icon} alt="" />
                      </div>
                      <div className="course-content-three">
                        <h4 className="text-yellow">
                          <span className="d-flex">
                            <CountUp start={0} end={200} delay={1} />+
                          </span>
                        </h4>
                        <p>Expert Tutors</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three">
                  <div className="align-items-center">
                    <div className="course-count-three course-count ms-0">
                      <div className="course-img">
                        <img className="img-fluid" src={Course03Icon} alt="" />
                      </div>
                      <div className="course-content-three">
                        <h4 className="text-info">
                          <span className="d-flex">
                            <CountUp start={0} end={6} delay={1} duration={5} />
                            K+
                          </span>
                        </h4>
                        <p>Ceritified Courses</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three mb-0">
                  <div className="align-items-center">
                    <div className="course-count-three">
                      <div className="course-img">
                        <img className="img-fluid" src={Course04Icon} alt="" />
                      </div>
                      <div className="course-content-three course-count ms-0">
                        <h4 className="text-green">
                          <span className="d-flex">
                            <CountUp start={0} end={6} delay={1} duration={2} />
                            K +
                          </span>
                        </h4>
                        <p>Online Students</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Online Courses */}

      {/* Master Skills Career */}
      <section className="master-skill-three">
        <div className="master-three-vector">
          <img className="ellipse-right img-fluid" src={Pattern01} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12" data-aos="fade-right">
              <div className="master-three-images">
                <div className="master-three-left">
                  <img
                    className="img-fluid"
                    src={Career}
                    alt="image-banner"
                    title="image-banner"
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12" data-aos="fade-left">
              <div className="home-three-head" data-aos="fade-up">
                <h2>Master the skills to drive your career</h2>
              </div>
              <div className="home-three-content" data-aos="fade-up">
                <p>
                  Get certified, master modern tech skills, and level up your
                  career whether you’re starting out or a seasoned pro. 95% of
                  eLearning learners report our hands-on content directly helped
                  their careers.
                </p>
              </div>
              <div className="skils-group">
                <div className="row">
                  <div
                    className="col-lg-6 col-xs-12 col-sm-6"
                    data-aos="fade-down"
                  >
                    <div className="skils-icon-item">
                      <div className="skils-icon">
                        <img
                          className="img-fluid"
                          src={Career01}
                          alt="certified"
                        />
                      </div>
                      <div className="skils-content">
                        <p className="mb-0">
                          Get certified with 100+ certification courses
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-xs-12 col-sm-6"
                    data-aos="fade-up"
                  >
                    <div className="skils-icon-item">
                      <div className="skils-icon">
                        <img
                          className="img-fluid"
                          src={Career02}
                          alt="Build skills"
                        />
                      </div>
                      <div className="skils-content">
                        <p className="mb-0">
                          Build skills your way, from labs to courses
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-xs-12 col-sm-6"
                    data-aos="fade-right"
                  >
                    <div className="skils-icon-item">
                      <div className="skils-icon">
                        <img
                          className="img-fluid"
                          src={Career03}
                          alt="Stay Motivated"
                        />
                      </div>
                      <div className="skils-content">
                        <p className="mb-0">
                          Stay motivated with engaging instructors
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-xs-12 col-sm-6"
                    data-aos="fade-left"
                  >
                    <div className="skils-icon-item">
                      <div className="skils-icon">
                        <img
                          className="img-fluid"
                          src={Career04}
                          alt="latest cloud"
                        />
                      </div>
                      <div className="skils-content">
                        <p className="mb-0">Keep up with the latest in cloud</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Courses */}
      <section className="home-three-courses">
        <div className="container">
          <div className="favourite-course-sec">
            <div className="row">
              <div
                className="home-three-head section-header-title"
                data-aos="fade-up"
              >
                <div className="row align-items-center d-flex justify-content-between">
                  <div className="col-lg-6 col-sm-8">
                    <h2>Courses</h2>
                  </div>
                  <div className="col-lg-6 col-sm-4">
                    <div className="see-all">
                      <Link to="/courses/">
                        See all
                        <span className="see-all-icon">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="all-corses-main">
                <div className="tab-content">
                  {/* All */}

                  <div className="all-course">
                    <div className="row">
                      {error ? (
                        <div className="col-12">
                          <p>{error}</p>
                        </div>
                      ) : (
                        courses.map((course, index) => (
                          <div
                            className="col-xl-3 col-lg-6 col-md-6 col-12 mb-4"
                            data-aos="fade-up"
                            key={index}
                          >
                            <div
                              className="course-box-three"
                              style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }} /* Ensuring the card takes full height */
                            >
                              <div className="course-three-item">
                                <div className="course-three-img">
                                  <Link to={`/course-details/${course.id}`}>
                                    <img
                                      className="img-fluid"
                                      alt={course.title}
                                      src={`${API_URL}/${course.banner}`}
                                      style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover",
                                      }} /* Ensuring the image is fixed height and covers area */
                                    />
                                  </Link>
                                </div>
                                <div
                                  className="course-three-content"
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="course-group-three">
                                    <div className="group-three-img">
                                      {/* Optional content here */}
                                    </div>
                                  </div>
                                  <div className="course-three-text">
                                    <Link to={`/course-details/${course.id}`}>
                                      <h3 className="title instructor-text">
                                        {course.title}
                                      </h3>
                                    </Link>
                                  </div>
                                  <div className="price-three-group d-flex align-items-center justify-content-between">
                                    <div className="price-three-view d-flex align-items-center"></div>
                                    <div className="price-three-time d-inline-flex align-items-center">
                                      <i className="fa-regular fa-clock me-2"></i>
                                      <span>{course.duration} hours</span>{" "}
                                      {/* Displaying the course duration */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>

                  {/* All */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Courses */}
      {/* CTA */}
      <section className="home-three-transform">
        <div className="container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-8 col-sm-12">
              <div className="cta-content">
                <h2>Transform Access To Education</h2>
                <p>
                  Create an account to receive our newsletter, course
                  recommendations and promotions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="transform-button-three">
                <Link to="/register" className="btn btn-action">
                  Get Started Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CTA */}

      {/* Trending Courses Three */}
      <section className="home-three-trending">
        <div className="container">
          <div className="row">
            <div
              className="home-three-head section-header-title"
              data-aos="fade-up"
            >
              <div className="row align-items-center d-flex justify-content-between">
                <div className="col-lg-12">
                  <h2>Most Trending Courses</h2>
                </div>
              </div>
            </div>
            <Trending3 />
          </div>
          {/* Favorite Course */}
        </div>
      </section>
      {/* Footer */}
      <Footer3 />
      {/* Footer */}
    </>
  );
};
