import React, { useEffect, useState, useContext } from "react";
import AdminSideBar from "../sidebar";
import { InstructorHeader } from "../../instructor/header";
import { getCourses, deleteCourse } from "../../../api/index";
import { Link } from "react-router-dom";
import { Icon1, Icon2 } from "../../imagepath";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext.js";

const AdminCourse = () => {
  const [courses, setCourses] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const API_URL = process.env.REACT_APP_MEDIA_URL;

  // State for delete confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseIdToDelete, setCourseIdToDelete] = useState(null);

  // Fetch courses and filter by created_by once the userEmail is available
  useEffect(() => {
    if (userInfo) {
      const fetchCourses = async () => {
        try {
          const response = await getCourses();
          const filteredCourses = response.data.results.filter(
            (course) => course.created_by === userInfo.email
          );
          setCourses(filteredCourses);
        } catch (error) {
          toast.error("Failed to fetch courses");
        }
      };

      fetchCourses();
    }
  }, [userInfo]);

  // Function to handle delete click
  const handleDeleteClick = (courseId) => {
    setCourseIdToDelete(courseId);
    setIsModalOpen(true);
  };

  // Function to confirm deletion
  const confirmDelete = async () => {
    try {
      await deleteCourse(courseIdToDelete);
      setCourses(courses.filter((course) => course.id !== courseIdToDelete));
      toast.success("Course deleted successfully");
    } catch (error) {
      toast.error("Failed to delete course");
    } finally {
      setIsModalOpen(false);
      setCourseIdToDelete(null);
    }
  };

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"My Course"} />
      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar breadcrumb-bar-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">My Courses</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        My Courses
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="page-content">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              <AdminSideBar />
              {/* /Sidebar */}
              {/* Courses */}
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-info">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>My Courses</h3>
                      <p>Manage your courses and its updates</p>
                    </div>
                    <div className="checkout-form pb-0">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="enroll-courses"
                        >
                          <div className="row">
                            {/* Course Grid */}
                            {courses.length > 0 ? (
                              courses.map((course) => (
                                <div
                                  className="col-xxl-4 col-md-6 d-flex"
                                  key={course.id}
                                >
                                  <div className="course-box flex-fill">
                                    <div className="product">
                                      <div className="product-img">
                                        <Link
                                          to={`/course-details/${course.id}`}
                                        >
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                              objectFit: "cover",
                                            }}
                                            className="img-fluid"
                                            alt="Course"
                                            src={`${API_URL}/${course.banner}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="product-content">
                                        <h3 className="title instructor-text">
                                          <Link
                                            to={`/course-details/${course.id}`}
                                          >
                                            {course.title}
                                          </Link>
                                        </h3>
                                        <div className="course-info d-flex align-items-center">
                                          <div className="rating-img d-flex align-items-center">
                                            <img src={Icon1} alt="Lessons" />
                                            <p>
                                              {course.lessonsCount}+ Lessons
                                            </p>
                                          </div>
                                          <div className="course-view d-flex align-items-center">
                                            <img src={Icon2} alt="Duration" />
                                            <p>{course.duration}</p>
                                          </div>
                                        </div>
                                        <div className="course-edit-btn d-flex align-items-center justify-content-between">
                                          <Link
                                            to={`/edit-course/${course.id}`}
                                          >
                                            <i className="bx bx-edit me-2" />{" "}
                                            Edit
                                          </Link>
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              handleDeleteClick(course.id)
                                            }
                                          >
                                            <i className="bx bx-trash me-2" />{" "}
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No courses found.</p>
                            )}
                            {/* /Course Grid */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bootstrap Modal for delete confirmation */}
        <div
          className={`modal fade ${isModalOpen ? "show" : ""}`}
          style={{ display: isModalOpen ? "block" : "none" }}
          tabIndex="-1"
          role="dialog"
          aria-hidden={!isModalOpen}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setIsModalOpen(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this course?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={confirmDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    </div>
  );
};

export default AdminCourse;
