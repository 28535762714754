// src/context/authContext.js

import React, { createContext, useState, useEffect } from 'react';
import SessionExpiredModal from '../components/session';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null); 
  const [userInfo, setUserInfo] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const role = localStorage.getItem('user_role'); 
    const userData = localStorage.getItem('user_info'); 
    
    setIsAuthenticated(!!token);
    setUserRole(role); 
    setUserInfo(userData ? JSON.parse(userData) : null);
    
    // Check for access token expiration
    const tokenDuration = localStorage.getItem('access_token_duration');
    if (tokenDuration) {
      const timeoutId = setTimeout(() => {
        // logout when the token expires
        logoutUser(); 
        // Show session expired modal
        setShowModal(true); 
      }, tokenDuration * 1000);

      return () => clearTimeout(timeoutId); 
    }
  }, []);

  const loginUser = (data) => {
    
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
    localStorage.setItem('access_token_duration', data.access_token_duration);
    localStorage.setItem('refresh_token', data.refresh_token_duration);
    // Save user role to localStorage
    localStorage.setItem('user_role', data.user.role); 
    localStorage.setItem('user_info', JSON.stringify(data.user));
  

    setIsAuthenticated(true);
    // Update state with user info
    setUserRole(data.user.role); 
    setUserInfo(data.user);
    setTimeout(logoutUser, data.access_token_duration * 1000);
   
  };


  const logoutUser = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token_duration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_role'); 
    localStorage.removeItem('user_info');

    setIsAuthenticated(false);
    setUserRole(null); 
    setUserInfo(null);

  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, userInfo, loginUser, logoutUser }}>
      {children}
      <SessionExpiredModal show={showModal} onClose={() => setShowModal(false)} />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

