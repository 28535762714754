import React, { useState } from 'react';
import { createCategory } from '../../../../api';  
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../footer";
import CourseHeader from "../../header";
import InstructorSidebar from "../../sidebar";

const CreateCategory = () => {
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !thumbnail) {
      toast.error('Please provide a title and upload a thumbnail image.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('thumbnail', thumbnail);

    setLoading(true);
    try {
      await createCategory(formData);
      toast.success('Category created successfully!');
      setTitle('');  // Reset form fields
      setThumbnail(null);
    } catch (error) {
      toast.error(error?.error || 'Failed to create category.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <CourseHeader />
        <div className="page-content">
          <div className="container">
            <div className="row">
              <InstructorSidebar />
              <div className="col-xl-9 col-lg-9">
                <div className="settings-widget card-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>Add A Category</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="checkout-form settings-wrap">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-block">
                              <label className="add-course-label">Category Title</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Category Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-block">
                              <label className="add-course-label">Category Thumbnail</label>
                              <div className="relative-form">
                                <span>{thumbnail ? thumbnail.name : "No File Selected"}</span>
                                <label className="relative-file-upload">
                                  Upload File
                                  <input
                                    type="file"
                                    name="thumbnail"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    required
                                  />
                                </label>
                              </div>
                            </div>
                            <button className="btn btn-primary btn-add-course" type="submit" disabled={loading}>
                              {loading ? 'Creating...' : 'Create Category'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
};

export default CreateCategory;
