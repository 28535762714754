// src/api/interceptor.js
import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  logoutUser,
} from "../utils/auth";
import { refreshToken } from "./index";

axios.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshTokenValue = getRefreshToken();
        const response = await refreshToken(refreshTokenValue);
        setAccessToken(response.data.access);
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${response.data.access}`;
        return axios(originalRequest);
      } catch (err) {
        logoutUser();
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
