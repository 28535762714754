import React, { useState, useEffect } from "react";
import StudentSidebar from "../sidebar";
import StudentHeader from "../header";
import { course02 } from "../../imagepath";
import { Link } from "react-router-dom";
import Footer from "../../footer";
import { getCourses } from '../../../api'; 

const StudentCourses = () => {
  const [isClassAdded, setIsClassAdded] = useState([false]);
  const [courses, setCourses] = useState([]);
  const API_URL = process.env.REACT_APP_MEDIA_URL;

// Fetch courses
useEffect(() => {
  const fetchCourses = async () => {
    try {
      const response = await getCourses(); 
      setCourses(response.data.results); 
    } catch (error) {
      toast.error('Failed to fetch courses');
    }
  };

  fetchCourses();
}, []);

// Truncate text function
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

  const toggleClass = (index) => {
    const updatedClasses = [...isClassAdded];
    updatedClasses[index] = !updatedClasses[index];
    setIsClassAdded(updatedClasses);
  };

  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Enrolled Courses"} />
      {/* Breadcrumb */}
      <div className="breadcrumb-bar breadcrumb-bar-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <h2 className="breadcrumb-title">Courses</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Courses
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* sidebar */}
            <StudentSidebar />
            {/* /Sidebar */}
            {/* Student Courses */}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-info">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Courses</h3>
                  </div>
                  <div className="checkout-form pb-0">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="enroll-courses"
                      >
                        <div className="row">
                          {/* Course Grid */}
                          {courses.length > 0 ? (
                    courses.map((course) => (
                      <div className="col-xxl-4 col-md-6 d-flex" key={course.id}>
                        <div className="course-box flex-fill">
                          <div className="product">
                            <div className="product-img">
                              <Link to={`/course-details/${course.id}`}>
                                <img className="img-fluid" alt="Course" src={`${API_URL}/${course.banner}`} />
                              </Link>
                            </div>
                            <div className="product-content">
                              <h3 className="title instructor-text">
                                <Link to={`/course-details/${course.id}`}>{course.title}</Link>
                              </h3>
                              <h6>{truncateText(course.description, 100)}</h6>
                              <div className="course-info d-flex align-items-center">
                                <div className="rating-img d-flex align-items-center">
                                <i className="fa fa-book course-card-icon" aria-hidden="true"></i>
                                  <p>10+ Lessons</p>
                                </div>
                                <div className="course-view d-flex align-items-center">
                                <i className="fa fa-clock course-card-icon" aria-hidden="true"></i>
                                  <p>{course.duration} hours</p>
                                </div>
                                
                              </div>
                              <div className="rating mb-0">
                                    <i className="fas fa-star filled me-1" />
                                    <i className="fas fa-star filled me-1" />
                                    <i className="fas fa-star filled me-1" />
                                    <i className="fas fa-star filled me-1" />
                                    <i className="fas fa-star filled me-1" />
                                    <span className="d-inline-block average-rating">
                                      <span>5.0</span> (20)
                                    </span>
                                  </div>
                              <div className="course-edit-btn d-flex align-items-center justify-content-between">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No courses found.</p>
                  )}
                          {/* /Course Grid */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dash-pagination">
                <div className="row align-items-center">
                  <div className="col-6">
                    <p>Page 1 of 2</p>
                  </div>
                  <div className="col-6">
                    <ul className="pagination">
                      <li className="active">
                        <Link to="#">1</Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="bx bx-chevron-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* /Student Courses */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      <Footer />
    </div>
  );
};

export default StudentCourses;
