// src/api/index.js
import axios from "axios";
import "./interceptor";

const API_URL = process.env.REACT_APP_API_URL;

// Register a new user
export const register = async (first_name, last_name, email, password) => {
  try {
    const response = await axios.post(`${API_URL}users/register/`, {
      first_name,
      last_name,
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Login a user
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}users/login/`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Refresh Token Function
export const refreshToken = async (refreshToken) => {
  try {
    const response = await axios.post(`${API_URL}users/token/refresh/`, {
      refresh: refreshToken, // Pass refresh token in request body
    });
    return response; // Return the response, which includes the new access token
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to refresh token" };
  }
};


// Logout a user
export const logout = (refreshToken) => {
  return axios.post(`${API_URL}users/logout/`, { refresh: refreshToken });
};

// Password reset request
export const passwordReset = (email) => {
  return axios.post(`${API_URL}users/password-reset/`, { email });
};

// Confirm password reset
export const passwordResetConfirm = (uidb64, token, newPassword) => {
  return axios.post(
    `${API_URL}users/password-reset-confirm/${uidb64}/${token}/`,
    { new_password: newPassword }
  );
};

// Get user profile
export const getUserProfile = (token) => {
  return axios.get(`${API_URL}users/profile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const changePassword = async (oldPassword, newPassword, token) => {
  try {
    const response = await axios.post(
      `${API_URL}users/profile/change-password/`,
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Update user profile
export const updateUserProfile = async (profileData, token) => {
  try {
    const formData = new FormData();

    Object.keys(profileData).forEach((key) => {
      if (profileData[key]) {
        formData.append(key, profileData[key]);
      }
    });

    const response = await axios.patch(
      `${API_URL}users/profile/update/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};



// Create a new category
export const createCategory = async (categoryData) => {
  try {
    const response = await axios.post(
      `${API_URL}courses/categories/`,
      categoryData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Get the list of categories
export const getCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}courses/categories/`);
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Retrieve a specific category by ID
export const getCategoryById = async (categoryId) => {
  try {
    const response = await axios.get(
      `${API_URL}courses/categories/${categoryId}/`
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Category not found." };
  }
};

// Update an existing category by ID
export const updateCategory = async (categoryId, categoryData) => {
  try {
    const response = await axios.put(
      `${API_URL}courses/categories/${categoryId}/`,
      categoryData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to update category." };
  }
};

// Delete a category by ID
export const deleteCategory = async (categoryId) => {
  try {
    const response = await axios.delete(
      `${API_URL}courses/categories/${categoryId}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.status === 204
      ? { message: "Category deactivated successfully." }
      : response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "Unable to delete category." };
  }
};

// Create a new course
export const createCourse = async (courseData) => {
  try {
    const response = await axios.post(`${API_URL}courses/create/`, courseData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { error: "An unknown error occurred" };
  }
};

// Get all Courses

export const getCourses = async () => {
  const response = await axios.get(`${API_URL}courses/all/`);
  return response;
};

// Get Featured Courses
export const getFeaturedCourses = (page = 1, token) => {
  return axios
    .get(`${API_URL}courses/featured/?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Add to Featured Courses (Admins only)
export const addToFeaturedCourses = (course_id, token) => {
  return axios
    .post(
      `${API_URL}courses/featured/add/`,
      { course_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Remove from Featured Courses - Admin
export const removeFromFeaturedCourses = (course_id, token) => {
  return axios
    .delete(`${API_URL}courses/featured/remove/`, {
      data: { course_id },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Single Course Information
export const getCourseDetails = (course_id) => {
  return axios
    .get(`${API_URL}courses/${course_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Update Course
export const updateCourse = (course_id, courseData, token) => {
  const formData = new FormData();
  Object.entries(courseData).forEach(([key, value]) =>
    formData.append(key, value)
  );

  return axios
    .put(`${API_URL}courses/${course_id}/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Delete Course
export const deleteCourse = (course_id, token) => {
  return axios
    .delete(`${API_URL}courses/${course_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Reactivate Course
export const reactivateCourse = (course_id, token) => {
  return axios
    .patch(`${API_URL}courses/${course_id}/reactivate/`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Assign Instructor to Course
export const assignInstructorToCourse = (course_id, instructor_id, token) => {
  return axios
    .post(
      `${API_URL}instructors/${course_id}/assign-instructor/`,
      { instructor_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Remove Instructor from Course
export const removeInstructorFromCourse = (course_id, instructor_id, token) => {
  return axios
    .delete(`${API_URL}instructors/${course_id}/remove-instructor/`, {
      data: { instructor_id },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Course Instructors
export const getCourseInstructors = (course_id, token) => {
  return axios
    .get(`${API_URL}instructors/${course_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Change Main Instructor
export const changeMainInstructor = (course_id, new_instructor_id, token) => {
  return axios
    .post(
      `${API_URL}${course_id}/change-main-instructor/`,
      { new_instructor_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// List Sections for a Course
export const listSectionsForCourse = (course_id, params, token) => {
  return axios
    .get(`${API_URL}courses/${course_id}/sections/`, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Create Section
export const createSection = (course_id, sectionData, token) => {
  return axios
    .post(`${API_URL}courses/${course_id}/sections/create/`, sectionData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Single Section Information
export const getSectionDetails = (course_id, section_id, token) => {
  return axios
    .get(`${API_URL}${course_id}/sections/${section_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Update Section
export const updateSection = (course_id, section_id, sectionData, token) => {
  return axios
    .put(`${API_URL}${course_id}/sections/${section_id}/`, sectionData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Delete Section
export const deleteSection = (course_id, section_id, token) => {
  return axios
    .delete(`${API_URL}${course_id}/sections/${section_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Reactivate Section
export const reactivateSection = (course_id, section_id, token) => {
  return axios
    .patch(`${API_URL}${course_id}/sections/${section_id}/reactivate/`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// list lessons
export const listLessonsForSection = (course_id, section_id, token) => {
  return axios
    .get(`${API_URL}courses/${course_id}/sections/${section_id}/lessons/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

//create lesson
export const createLesson = (course_id, section_id, lessonData, token) => {
  const formData = new FormData();
  formData.append("title", lessonData.title);
  formData.append("content_type", lessonData.content_type);

  if (lessonData.content_file) {
    formData.append("content_file", lessonData.content_file);
  }

  if (lessonData.content) {
    formData.append("content", lessonData.content);
  }

  //formData.append("order", lessonData.order);

  return axios
    .post(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/create/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get a Single Lesson
export const getLessonDetails = (course_id, section_id, lesson_id, token) => {
  return axios
    .get(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Update a Lesson

export const updateLesson = (
  course_id,
  section_id,
  lesson_id,
  lessonData,
  token
) => {
  const formData = new FormData();
  formData.append("title", lessonData.title);
  formData.append("content_type", lessonData.content_type);

  if (lessonData.content_file) {
    formData.append("content_file", lessonData.content_file);
  }

  if (lessonData.content) {
    formData.append("content", lessonData.content);
  }

  //formData.append("order", lessonData.order);

  return axios
    .put(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// delete a lesson
export const deleteLesson = (course_id, section_id, lesson_id, token) => {
  return axios
    .delete(
      `${API_URL}courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Get Course Progress
export const getCourseProgress = async (course_id, token) => {
  try {
    const response = await axios.get(`${API_URL}courses/${course_id}/progress/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { error: "An unknown error occurred" };
  }
};

// Update Lesson Progress
export const updateLessonProgress = async (course_id, lessonData, token) => {
  try {
    const response = await axios.post(`${API_URL}courses/${course_id}/progress/`, lessonData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { error: "An unknown error occurred" };
  }
};

// List Enrollments
export const listEnrollments = (params, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Retrieve Enrollment Details
export const getEnrollmentDetails = (enrollment_id, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/${enrollment_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Enroll a User
export const enrollInCourse = (course_id, token) => {
  return axios
    .post(
      `${API_URL}courses/enrollments/enroll/`,
      { course_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Unenroll a User from Course
export const unenrollFromCourse = (enrollment_id, token) => {
  return axios
    .delete(`${API_URL}courses/enrollments/${enrollment_id}/unenroll/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Check Enrollment Status
export const checkEnrollmentStatus = (course_id, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/status/${course_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Retrieve User Enrollments
export const getUserEnrollments = (user_id, params, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/enrollment/user/${user_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Retrieve Course Enrollments
export const getCourseEnrollments = (course_id, params, token) => {
  return axios
    .get(`${API_URL}courses/enrollments/course/${course_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

//
