import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { LoginImg, logo, NetIcon1 } from "../../imagepath";
import { register } from "../../../api"; // Import the register API method

const Register = () => {
  const [eye, seteye] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const navigate = useNavigate();

  // const onEyeClick = () => {
  //   seteye(!eye);
  // };

  // Check  passwords match 
  useEffect(() => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setPasswordMatchError("Passwords do not match.");
      } else {
        setPasswordMatchError("");
      }
    }
  }, [password, confirmPassword]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await register(firstName, lastName, email, password);
      navigate("/login"); 
    } catch (err) {
      setError(err.error || "An unknown error occurred.");
    }
  };

  return (
    <div className="main-wrapper log-wrap">
      <div className="row">
        {/* Login Banner */}
        <div className="col-md-6 login-bg">
          <OwlCarousel
            items={1}
            margin={25}
            dots={true}
            nav={true}
            loop={true}
            responsive={{
              0: { items: 1 },
              768: { items: 1 },
              1170: { items: 1 },
            }}
          >
            <div className="welcome-login">
              <div className="login-banner">
                <img src={LoginImg} className="img-fluid" alt="Logo" />
              </div>
              <div className="mentor-course text-center">
                <h2>Welcome to JIFUNZE</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </OwlCarousel>
        </div>
        {/* /Login Banner */}
        <div className="col-md-6 login-wrap-bg">
          {/* Register */}
          <div className="login-wrapper">
            <div className="loginbox">
              <div className="img-logo">
                <img src={logo} className="img-fluid" alt="Logo" />
                <div className="back-home">
                  <Link to="/home">Back to Home</Link>
                </div>
              </div>
              <h1>Sign up</h1>
              <form onSubmit={handleRegister}>
                <div className="input-block">
                  <label className="form-control-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-block">
                  <label className="form-control-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-block">
                  <label className="form-control-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="input-block">
                  <label className="form-control-label">Password</label>
                  <div className="pass-group">
                    <input
                      className="form-control pass-input"
                      placeholder="Enter your password"
                      type={eye ? "password" : "text"}
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    {/* <span
                        onClick={onEyeClick}
                        className={`fa toggle-password feather-eye" ${
                          eye ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                      <span className="toggle-password feather-eye"></span>
                      <span className="pass-checked">
                        <i className="feather-check"></i>
                      </span>  */}
                  </div>
                </div>
                <div className="input-block">
                  <label className="form-control-label">Confirm Password</label>
                  <div className="pass-group">
                    <input
                      className="form-control"
                      placeholder="Confirm your password"
                      type="password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                  </div>
                </div>
                {passwordMatchError && (
                  <div style={{ color: "red", fontSize: "14px" }}>{passwordMatchError}</div>
                )}
                {error && (
                  <div style={{ color: "red", fontSize: "14px" }}>{error}</div>
                )}
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-primary btn-start"
                    disabled={passwordMatchError !== ""}
                  >
                    Create Account
                  </button>
                </div>
              </form>
            </div>
            <div className="google-bg text-center">
              <span>
                <Link to="#">Or sign in with</Link>
              </span>
              <div className="sign-google">
                <ul>
                  <li>
                    <Link to="#">
                      <img src={NetIcon1} className="img-fluid" alt="Logo" />
                      Sign In using Google
                    </Link>
                  </li>
                </ul>
              </div>
              <p className="mb-0">
                Already have an account? <Link to="/login">Sign in</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
