import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link, useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { StudentIconThree } from "../../imagepath";
import { getCourses } from "../../../api";

export const Trending3 = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        setCourses(response.data.results); // Assuming the courses are in `response.data.results`
      } catch (err) {
        setError("Failed to fetch courses");
      }
    };

    fetchCourses();
  }, []);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  var settings = {
    items: 3, // Keep the number of items visible fixed to 3
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],
    loop: courses.length > 3, // Only enable loop if there are more than 3 courses
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2, // Keep 2 items for tablet size
      },
      1170: {
        items: 3, // Display 3 items for large screens
      },
    },
  };

  return (
    <OwlCarousel
      {...settings}
      className="owl-carousel home-three-trending-course owl-theme"
      data-aos="fade-up"
    >
      {courses.length > 0 ? (
        courses.map((course) => (
          <div className="trending-three-item" key={course.id}>
            <div className="trending-content-top trending-bg-one">
              <div className="course-title-one">
                <Link to="/home">{course.categories[0]?.title || "N/A"}</Link>
              </div>
              <div className="trending-three-text">
                <Link to="/home">
                  <h3 className="title instructor-text">{course.title}</h3>
                  <p>
                    {truncateText(course.description, 150)}
                  </p>
                </Link>
              </div>

              <div className="trending-counts-info d-flex align-items-center justify-content-between">
                <div className="trending-three-counts d-flex align-items-center">
                  <img src={StudentIconThree} alt="student-icon" />
                  <p>10+ Students</p>
                </div>
                <div className="price-three-time d-inline-flex align-items-center">
                  <i className="fa-regular fa-clock me-2"></i>
                  <span>{course.duration ? `${course.duration} hours` : "N/A"}</span>
                </div>
              </div>
            </div>
            <div className="trending-content-footer">
              <div className="trending-price-group d-flex align-items-center justify-content-between">
                <div className="trending-group-view">
                  <div className="trending-price-three">
                    <h3>FREE</h3>
                  </div>
                </div>
                <div className="trending-footer-img">
                  <Link to={`/course-details/${course.id}`}>
                    <img
                      src={API_URL + course.icon}
                      alt="course-icon"
                      style={{ maxWidth: "50px", height: "auto", border: "2px solid #000" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>{error ? error : "Loading courses..."}</p>
      )}
    </OwlCarousel>
  );
};
