import React, { useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import AdminSidebar from "../sidebar"
import Footer from "../../footer";
import SettingsPageHeader from "./settingsPageHeader";
import { Link , useNavigate } from "react-router-dom";
import { changePassword } from "../../../api"; 
import { toast, ToastContainer } from "react-toastify"; 

const AdminChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleChangePassword = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match.");
      return;
    }
    
    try {
      
      await changePassword(currentPassword, newPassword);
      toast.success("Password changed successfully.");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      if (error.response?.status === 400) {
        toast.error("Error: " + (error.response.data.old_password || error.response.data.new_password || "Invalid input data."));
      } else if (error.response?.status === 401) {
        toast.error("Authentication credentials were not provided.");
      } else {
        toast.error("An unknown error occurred.");
      }
    }
  };

  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Settings"} />
      {/* Breadcrumb */}
      <div className="breadcrumb-bar breadcrumb-bar-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <h2 className="breadcrumb-title">Settings</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Change Password
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminSidebar />
            {/* /Sidebar */}
            {/* Instructor Settings */}
            <div className="col-xl-9 col-lg-9">
              <div className="settings-widget card-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Settings</h3>
                    <p>You have full control to manage your own account settings</p>
                  </div>
                  <SettingsPageHeader />
                  <form onSubmit={handleChangePassword}>
                    <div className="checkout-form settings-wrap">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block">
                            <label className="form-label">Current Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={currentPassword}
                              onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                          </div>
                          <div className="input-block">
                            <label className="form-label">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                          <div className="input-block">
                            <label className="form-label">Re-type New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                          </div>
                          <button className="btn btn-primary" type="submit">
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* /Instructor Settings */}
          </div>
        </div>
      </div>
      <Footer />
      {/* /Page Content */}
      <ToastContainer />
    </div>
  );
};

export default AdminChangePassword;
