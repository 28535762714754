import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoPng, logoSvg } from "../imagepath";
import { AuthContext } from "../../context/AuthContext"; 

export const Header3  = (activeMenu) => {
  const { isAuthenticated, userRole } = useContext(AuthContext); 
  const navigate = useNavigate(); 

  // change header background on scroll
  const [navbar, setNavbar] = useState(false);

  const changeHeaderBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // Mobile Menu toggle
  const [mobileSubMenu, setMobileSubMenu] = useState(false);

  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
  };

  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
  };

  // set path based on user role
  const getDashboardPath = () => {
    if (userRole === "learner") {
      return "/student/student-dashboard";
    } else if (userRole === "superadmin") {
      return "/admin/admin-dashboard";
    } else if (userRole === "instructor") {
      return "/instructor/instructor-dashboard";
    }
    return "/"; 
  };

  window.addEventListener("scroll", changeHeaderBackground);

  return (
    <header className="header-three">
      <div className="header-fixed-three header-fixed">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav-three scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav-three scroll-sticky"
          }
        >
          <div className="container">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#" onClick={openMobileMenu}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link to="/home" className="navbar-brand logo">
                <img src={logoPng} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/home" className="menu-logo">
                  <img src={logoSvg} className="img-fluid" alt="Logo" />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={hideMobileMenu}
                >
                  <i className="fas fa-times"></i>
                </Link>
              </div>
              <ul className="main-nav"></ul>
            </div>
            <ul className="nav header-navbar-rht align-items-center">
              {!isAuthenticated ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link login-three-head button" to="/login">
                      <span>Login</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link signin-three-head" to="/register">
                      Register
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link signin-three-head" to="/courses">
                      View Courses
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link login-three-head button"
                      to={getDashboardPath()}
                    >
                      Go to Dashboard
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};
