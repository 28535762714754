import React, { useState, useEffect, useContext } from "react";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserEnrollments } from "../../../api";
import { AuthContext } from "../../../context/AuthContext";

const StudentDashboard = () => {
  const [isClassAdded, setIsClassAdded] = useState([false]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const { userInfo } = useContext(AuthContext); // Retrieve userInfo from AuthContext
  const API_URL = process.env.REACT_APP_MEDIA_URL;

  // Fetch enrolled courses
  useEffect(() => {
    const fetchEnrolledCourses = async () => {
      if (!userInfo) return;

      try {
        const response = await getUserEnrollments(userInfo.id); // Use userInfo.id to get enrollments

        setEnrolledCourses(response.data.results); // Access enrolled courses from response.data.results
      } catch (error) {
        toast.error("Failed to fetch enrolled courses");
      }
    };

    fetchEnrolledCourses();
  }, [userInfo]);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const toggleClass = (index) => {
    const updatedClasses = [...isClassAdded];
    updatedClasses[index] = !updatedClasses[index];
    setIsClassAdded(updatedClasses);
  };

  return (
    <>
      {/* Main Wrapper */}
      <div className="main-wrapper">
        {/* Header */}
        <StudentHeader activeMenu={"Dashboard"} />
        {/* /Header */}
        {/* Breadcrumb */}
        <div className="breadcrumb-bar breadcrumb-bar-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">Dashboard</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Dashboard
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="page-content">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              <StudentSidebar />
              {/* /Sidebar */}
              {/* Student Dashboard */}
              <div className="col-xl-9 col-lg-9">
                {/* Dashboard Title */}
                <div className="dashboard-title">
                  <h4>Enrolled Courses</h4>
                </div>
                <div className="row">
                  {/* Course Grid */}
                  {enrolledCourses.length > 0 ? (
                    enrolledCourses.map((enrollment) => {
                      const { course } = enrollment;
                      return (
                        <div
                          className="col-xxl-4 col-md-6 d-flex"
                          key={course.id}
                        >
                          <div className="course-box flex-fill">
                            <div className="product">
                              <div className="product-img">
                                <Link to={`/course-details/${course.id}`}>
                                  <img
                                    className="img-fluid"
                                    alt="Course"
                                    src={`${API_URL}/${course.banner}`}
                                  />
                                </Link>
                              </div>
                              <div className="product-content">
                                <h3 className="title instructor-text">
                                  <Link to={`/course-details/${course.id}`}>
                                    {course.title}
                                  </Link>
                                </h3>
                                <h6>{truncateText(course.description, 100)}</h6>
                                <div className="course-info d-flex align-items-center">
                                  <div className="rating-img d-flex align-items-center">
                                    <i
                                      className="fa fa-book course-card-icon"
                                      aria-hidden="true"
                                    ></i>
                                    <p>10+ </p>
                                  </div>
                                  <div className="course-view d-flex align-items-center">
                                    <i
                                      className="fa fa-clock course-card-icon"
                                      aria-hidden="true"
                                    ></i>
                                    <p>{course.duration} hours</p>
                                  </div>
                                </div>
                                <div className="rating mb-0">
                                  <i className="fas fa-star filled me-1" />
                                  <i className="fas fa-star filled me-1" />
                                  <i className="fas fa-star filled me-1" />
                                  <i className="fas fa-star filled me-1" />
                                  <i className="fas fa-star filled me-1" />
                                  <span className="d-inline-block average-rating">
                                    <span>5.0</span> (20)
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No enrolled courses found.</p>
                  )}
                  {/* /Course Grid */}
                </div>
              </div>
              {/* Student Dashboard */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {/* /Main Wrapper */}
    </>
  );
};

export default StudentDashboard;
