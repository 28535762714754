import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useContext } from "react";
import { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { User11 } from "../../../imagepath"; 
import { AuthContext } from "../../../../context/AuthContext"; 

const HeadModule = () => {
  const [setShowCart] = useState(false);
  const [setShowProfile] = useState(false);
  const { userInfo } = useContext(AuthContext); 
  const location = useLocation();
  const navigate = useNavigate();
  const {logoutUser } = useContext(AuthContext);

 

  // Refs
  const cart = useRef();
  useOnClickOutside(cart, () => setShowCart(false));

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));


  const handleLogout = (e) => {
    e.preventDefault();
    logoutUser();
    navigate ('/login');
  }



  return (
    <>
      <ul className="nav header-navbar-rht">
        <li className="nav-item user-nav">
          <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown">
            <span className="user-img">
              <img
                src={userInfo?.profile_picture || User11} 
                alt="User"
              />
              <span className="status online" />
            </span>
          </Link>
          <div
            className="users dropdown-menu dropdown-end dropdown-menu-right"
            data-popper-placement="bottom-end"
          >
            <div className="user-header">
              <div className="avatar avatar-sm">
                <img
                  src={userInfo?.profile_picture || User11} 
                  alt="User Image"
                  className="avatar-img rounded-circle"
                />
              </div>
              <div className="user-text">
                <h6>{userInfo?.first_name} {userInfo?.last_name}</h6> 
                <p className="text-muted mb-0">
  {userInfo?.role === 'learner' ? 'Student' : userInfo?.role}
</p>

              </div>
            </div>
            <Link className="dropdown-item" to="/setting-edit-profile">
              <FeatherIcon icon="user" className="me-1" /> Profile
            </Link>
            
            <Link className="dropdown-item"  onClick={handleLogout}>
              <FeatherIcon icon="log-out" className="me-1" /> Logout
            </Link>
          </div>
        </li>
      </ul>
    </>
  );
};

export default HeadModule;
